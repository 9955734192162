.box {
  width: 100%;
  position: absolute;
  background-color: burlywood;
  /* margin-left: 3rem;
  margin-right: 3rem; */
  padding: 20px;
  /* max-width: 1100px; */
  font-family: sans-serif;
  overflow: hidden;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url(../Assets/Fonts/Montserrat-Bold.otf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url(../Assets//Fonts/Montserrat-Regular.otf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Thin";
  src: local("Montserrat-Thin"),
    url(../Assets//Fonts/Montserrat-Thin.otf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url(../Assets//Fonts/Montserrat-Medium.otf) format("truetype");
}

.text{
  float: left;

}

.big-button{
  float: right;

}

.input-field{
float: right;
}

.small-button{

float: right;
background-color: lightcyan;
border-radius: 4px;

}


.table-title{
text-align: left;
}

table {
border-collapse: collapse;
border: 2px solid rgb(200,200,200);
letter-spacing: 1px;
font-size: 0.8rem;
padding: 5px;
width: 100%;
/* margin-left: auto;
margin-right: auto; */
}

td, th {
border: 1px solid rgb(190,190,190);
padding: 5px 10px;
}

th {
background-color: rgb(235,235,235);
width: 6.25%;
}

td {
text-align: center;
}

tr:nth-child(even) td {
background-color: rgb(250,250,250);
}

tr:nth-child(odd) td {
background-color: rgb(245,245,245);
}

caption {
padding: 10px;
}

table {
  margin: 0 auto; /* or margin: 0 auto 0 auto */
}